<template>
  <div class="page">

    <!--居中对齐-->
    <div class="notice-wrapper" v-show="ui.noticeBarStatus">
      <div class="center">
        <el-button
            size="small"
            :plain="false"
            type="primary"
            style="opacity: 0.5;display: block;width: 100%;margin-top: 5px;white-space: nowrap;"
            @click="goBack()">
          <i class="fa fa-warning"></i> 请勿关闭，请点击 " 此按钮 " 以 <span style="color:red">" 继续{{caption.to=='detail'?'查看':'完善'}} "</span>
          {{caption.tableTitle}}！
        </el-button>
      </div>
    </div>

    <!--图片区域-->
    <div class="image-wrapper" :style="'background-image: url('+apiUrl+'/'+caption.url+')'">

    </div>

  </div>

</template>

<script>
import config from '/vue.config.js'

export default {
  name: "showImageDetail",
  data() {
    return {

      // 基础网址
      apiUrl: process.env.VUE_APP_BASE_API,

      // 界面元素状态
      ui: {
        noticeBarStatus: true,
      },

      // 输入参数
      caption: {
        tableName: '',
        tableAlias: '',
        tableTitle: '',
        keyIdName: '',
        id: '',
        title: '',
      },

    }
  },
  created() {

    // 初始化
    this.initParm();
  },
  mounted() {

    // 检测窗体变化
    window.onresize = () => {
      return (() => {
        this.setResize();
      })()
    }

  },
  methods: {

    // 初始化参数
    initParm() {

      // 输入参数
      let e = this.$route.query;

      // 参数到模型
      for (let key in e) {
        if (key == "tableName") {
          let tableName = e[key];
          let tableAlias = tableName.replace(/_/g, "");
          tableAlias = tableAlias.toLowerCase();
          this.caption.tableAlias = tableAlias;
          this.caption[key] = tableName;
        } else if(key == "url"){
          this.caption[key] = decodeURIComponent(e[key]).replace(/_thumb/g, "");
        }else {
          this.caption[key] = e[key];
        }
      }

      this.setResize();
    },

    // 检测窗体变化
    setResize() {
      this.$nextTick(() => {
        let width = document.documentElement.clientWidth;
        if (width < 768) {
          this.ui.labelWidth = '80px';
        } else {
          this.ui.labelWidth = '120px';
        }
      });
    },

    // 关闭
    goBack() {

      if(this.caption["from"]){
        window.parent.closeDialog();
      }else{
        this.$router.go(-1);
      }

    },

  },

}
</script>

<style lang="scss">

/*--基础+加载动画--*/
html, body, #app, .page {

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  animation: page-fade-in 1s forwards;
  background: url(../../static/images/bodyback.jpg);
}

@keyframes page-fade-in {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

/*--通知性质栏--*/
.notice-wrapper {

  margin: 10px;
  margin-top: 10px;
  padding: 0px 5px;
  height: 42px;
  line-height: 40px;

  background: #FFF;
  border-radius: 6px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);

}

/*--图片区域--*/
.image-wrapper{

  margin: 0px 10px;
  padding: 40px 20px;

  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);
  background-color: #FFF;
  height: calc(100% - 150px);

  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}

</style>